<template>
  <div class="service-box">
    <div class="title">{{ $t('carService.title') }}</div>
    <div class="swiper centeredAutoSwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide slide1">
          <img :src="imgList.airport[0]" />
          <div class="label">{{ $t('carService.swiper1.label') }}</div>
          <div class="desc">{{ $t('carService.swiper1.desc') }}</div>
        </div>
        <div class="swiper-slide slide2">
          <div class="left">
            <img :src="imgList.commerce[0]" />
            <div class="desc">
              {{ $t('carService.swiper2.desc') }}
            </div>
          </div>
          <div class="right">
            <div class="label">{{ $t('carService.swiper2.label') }}</div>
            <img :src="imgList.commerce[1]" />
          </div>
        </div>
        <div class="swiper-slide slide3">
          <div class="flex flex-col">
            <div class="top">
              <img :src="imgList.tour[0]" />
              <div class="label">{{ $t('carService.swiper3.label') }}</div>
            </div>
            <div class="bottom">
              <img :src="imgList.tour[1]" />
              <div class="desc">
                {{ $t('carService.swiper3.desc') }}
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide4">
          <div class="label">{{ $t('carService.swiper4.label') }}</div>
          <div class="flex img-box">
            <img :src="imgList.golf[0]" />
            <img :src="imgList.golf[1]" />
            <img :src="imgList.golf[2]" />
          </div>
          <div class="desc">
            {{ $t('carService.swiper4.desc') }}
          </div>
        </div>
        <div class="swiper-slide slide5">
          <div class="label">{{ $t('carService.swiper5.label') }}</div>
          <img :src="imgList.other[0]" />
          <div class="desc">{{ $t('carService.swiper5.desc') }}</div>
        </div>
      </div>
      <div class="custom-pagination flex row-center col-center">
        <div class="pagination-box flex row-center col-center">
          <div
            class="circle"
            v-for="(item, index) in 5"
            :key="index"
            @click="updateActiveIndex(index)"
            :class="activeIndex === index ? 'active' : ''"
          >
            <div
              class="progress"
              :style="{
                animationDuration: time + 'ms',
                animationPlayState: isPlaying ? 'running' : 'paused'
              }"
            ></div>
          </div>
        </div>
        <div
          class="play flex row-center col-center"
          v-if="isPlaying"
          @click="changeAnimationPlayState(false)"
        >
          <span class="iconfont icon-kongzhitaizanting"></span>
        </div>
        <div class="play flex row-center col-center" v-else @click="changeAnimationPlayState(true)">
          <span class="iconfont icon-kongzhitaibofang"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default {
  name: 'CenteredAutoSwiper',
  data() {
    return {
      activeIndex: 0,
      time: 5000,
      isPlaying: false,
      centeredAutoSwiper: null,
      imgList: {
        golf: [
          process.env.VUE_APP_FILE_URL + 'index/car/golf/1.png',
          process.env.VUE_APP_FILE_URL + 'index/car/golf/2.png',
          process.env.VUE_APP_FILE_URL + 'index/car/golf/3.png'
        ],
        tour: [
          process.env.VUE_APP_FILE_URL + 'index/car/tour/1.png',
          process.env.VUE_APP_FILE_URL + 'index/car/tour/2.png'
        ],
        airport: [process.env.VUE_APP_FILE_URL + 'index/car/airport/1.png'],
        other: [process.env.VUE_APP_FILE_URL + 'index/car/other/1.png'],
        commerce: [
          process.env.VUE_APP_FILE_URL + 'index/car/commerce/1.png',
          process.env.VUE_APP_FILE_URL + 'index/car/commerce/2.png'
        ]
      }
    }
  },
  props: {
    scrollY: {
      type: Number,
      default: 0
    },
    aboutUsHeight: {
      type: Number,
      default: 0
    }
  },
  watch: {
    scrollY(val) {
      if (
        val > document.documentElement.clientHeight + this.aboutUsHeight * 0.94 &&
        !this.isPlaying
      ) {
        this.isPlaying = true
        this.centeredAutoSwiper.autoplay.start()
      }
    },
    '$i18n.locale'() {
      window.location.reload()
    }
  },
  mounted() {
    if (this.$route.path === '/car-service') {
      this.isPlaying = true
    }
    this.initSwiper()
  },
  methods: {
    initSwiper() {
      const that = this
      that.centeredAutoSwiper = new Swiper('.centeredAutoSwiper', {
        slidesPerView: 'auto',
        centeredSlides: true,
        autoplay: {
          delay: that.time,
          disableOnInteraction: false
        },
        loop: true,
        loopAdditionalSlides: 100,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          slideChange: function() {
            const swiper = this
            that.activeIndex = swiper.realIndex
          }
        }
      })
      if (!this.isPlaying) {
        this.centeredAutoSwiper.autoplay.stop()
      }
    },
    changeAnimationPlayState(flag) {
      this.isPlaying = flag
      if (!flag) {
        this.centeredAutoSwiper.autoplay.stop()
      } else {
        this.centeredAutoSwiper.autoplay.start()
      }
    },
    updateActiveIndex(index) {
      this.activeIndex = index
      this.centeredAutoSwiper.slideTo(index)
    }
  }
}
</script>

<style scoped lang="scss">
.service-box {
  background-color: $bg-color;
  padding-bottom: 157px;
  .title {
    color: rgba(255, 255, 255, 0.56);
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px;
    padding: 143px 0 40px 330px;
  }
  .swiper {
    width: 100%;
    height: 100%;
    //overflow: hidden;
    //position: relative;
    .swiper-slide {
      width: 1260px;
      height: 680px;
      color: #ffffff;
      margin-left: 20px;
      box-sizing: border-box;
      border-radius: 28px;
      background: #000;
      .label,
      .desc {
        font-weight: bold;
        font-size: 40px;
        line-height: 58px;
        letter-spacing: 0.2px;
        white-space: pre-line;
      }
      .desc {
        color: $light-color;
        font-weight: normal;
      }
    }
    .slide1 {
      margin-left: 0;
      display: flex;
      img {
        width: 896px;
        height: 580px;
        border-radius: 28px;
        margin: 50px 0 50px 50px;
      }
      .label {
        margin: 50px 0 0 89px;
        text-align: right;
      }
      .desc {
        position: absolute;
        bottom: 60px;
        left: 80px;
        width: 1100px;
      }
    }
    .slide2 {
      display: flex;
      .left {
        position: relative;
        z-index: 2;
        white-space: nowrap;
        margin: 50px 0 0 50px;
        img {
          width: 664px;
          height: 430px;
          border-radius: 28px;
        }
        .desc {
          margin: 24px 0 0 30px;
          min-width: 1100px;
          position: absolute;
        }
      }
      .right {
        position: relative;
        z-index: 1;
        margin-left: -256px;
        padding: 44px 50px 0 0;
        img {
          width: 751px;
          height: 486px;
          border-radius: 28px;
        }
        .label {
          margin-bottom: 42px;
          text-align: right;
          padding: 0 14px 0 0;
        }
      }
    }
    .slide3 {
      img {
        width: 896px;
        height: 340px;
        margin-left: 234px;
      }
      .top {
        height: 340px;
        .label {
          position: absolute;
          top: 44px;
          right: 64px;
        }
      }
      .bottom {
        height: 340px;
        .desc {
          position: absolute;
          bottom: 60px;
          left: 80px;
          width: 1100px;
        }
      }
    }
    .slide4 {
      .label {
        text-align: right;
        padding: 44px 64px 30px 0;
      }
      .img-box {
        img {
          &:first-child {
            width: 385px;
            height: 369px;
          }
          &:nth-child(2) {
            width: 570px;
            height: 369px;
          }
          &:last-child {
            width: 305px;
            height: 369px;
          }
        }
      }
      .desc {
        width: 1100px;
        position: absolute;
        bottom: 60px;
        left: 80px;
      }
    }
    .slide5 {
      margin-right: 20px;
      overflow: hidden;
      .label {
        text-align: right;
        padding: 44px 64px 32px 0;
      }
      img {
        width: 896px;
        height: 546px;
        border-radius: 28px;
        margin-left: 182px;
        margin-top: 34px;
      }
      .desc {
        position: absolute;
        width: 1100px;
        bottom: 60px;
        left: 80px;
      }
    }
    .custom-pagination {
      //position: absolute;
      //z-index: 10;
      //bottom: 10%;
      //width: 100%;
      gap: 14px;
      margin-top: -46px;
      position: relative;
      z-index: 9;

      .pagination-box {
        width: 216px;
        height: 56px;
        border-radius: 32px;
        background: rgba(66, 66, 69, 0.7);
        box-shadow: 0 0 1px 0 rgba(232, 232, 237, 0.11) inset;
        backdrop-filter: blur(3.5px);
        gap: 16px;
        .circle {
          width: 8px;
          height: 8px;
          border-radius: 10px;
          background-color: rgba(245, 245, 247, 0.8);
          cursor: pointer;
        }
        .active {
          width: 48px;
          overflow: hidden;
          .progress {
            border-radius: 10px;
            height: 8px;
            background-color: white;
            animation-name: animate-progress;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            @keyframes animate-progress {
              0% {
                width: 0;
              }

              to {
                width: 100%;
              }
            }
          }
        }
      }
      .play {
        border-radius: 32px;
        background: rgba(66, 66, 69, 0.7);
        box-shadow: 0 0 1px 0 rgba(232, 232, 237, 0.11) inset;
        backdrop-filter: blur(3.5px);
        width: 56px;
        height: 56px;
        .iconfont {
          font-size: 56px;
          color: $light-color;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
